import React from "react";
import { string, number, array } from "prop-types";
import { animated, interpolate } from "react-spring/hooks";
import get from "lodash.get";

import {
  ma,
  CardOuterBig,
  CardEnglishBig,
  CardMāoriBig,
  CardBreakdownBig,
  StoryPartBig,
  B,
} from "../styledComponents";

const Card = ({ i, x, y, rot, scale, trans, bind, data }) => {
  const { content, heading, body, extra } = data[i];
  const item = { type: "green", word: "grateful" };

  return (
    <animated.div
      key={i}
      style={{
        transform: interpolate(
          [x, y],
          (x, y) => `translate3d(${x}px,${y}px,0)`
        ),
      }}
      className="card-deck__card-outer"
    >
      <animated.div
        {...bind(i)}
        style={{
          transform: interpolate([rot, scale], trans),
        }}
      >
        <div className="card card-deck__card-inner">
          <CardOuterBig type={item.type}>
            <CardEnglishBig>{item.word}</CardEnglishBig>
            <CardMāoriBig>
              {get(ma, [item.type, item.word, "ma"], "")}
            </CardMāoriBig>
            <CardBreakdownBig
            // dangerouslySetInnerHTML={{
            //   __html:
            //     <StoryPartBig> +
            //     get(ma, [item.type, item.word, "story"], "").split(
            //       "\n",+
            //     "</StoryPartBig>",
            // }}
            >
              {get(ma, [item.type, item.word, "story"], "")
                .split("\n")
                .map((phrase) => (
                  <StoryPartBig>
                    {phrase.split(":").map((section, i) =>
                      i === 0 ? (
                        <B>{section}</B>
                      ) : (
                        <>
                          {section ? ":  " : ""}
                          {section}
                        </>
                      )
                    )}
                  </StoryPartBig>
                ))}
            </CardBreakdownBig>
          </CardOuterBig>
        </div>
      </animated.div>
    </animated.div>
  );
};

Card.propTypes = {
  front: string,
  back: string,
  heading: string,
  body: string,
  extra: string,
};

export default Card;
