export default [
  "agency",
  "appreciation",
  "attention",
  "authenticity",
  "beauty",
  "belonging",
  "care",
  "celebration",
  "challenge",
  "choice",
  "clarity",
  "collaboration",
  "communication",
  "community",
  "compassion",
  "competence",
  "confidence",
  "connection",
  "consideration",
  "consistency",
  "contribution",
  "dignity",
  "discovery",
  "ease",
  "effectiveness",
  "efficiency",
  "empathy",
  "family health",
  "flow",
  "friendship",
  "growth",
  "honesty",
  "hope",
  "humour",
  "inclusion",
  "information",
  "inspiration",
  "integrity",
  "intimacy",
  "learning",
  "love",
  "mastery",
  "mental health",
  "mourning",
  "movement/exercise",
  "mutuality",
  "nourishment",
  "order",
  "partnership",
  "peace",
  "peace-of-mind",
  "perspective",
  "physical health",
  "physical safety",
  "play",
  "presence",
  "privacy",
  "progress",
  "psychological safety",
  "purpose",
  "rest",
  "security",
  "self-compassion",
  "self-expression",
  "sexual expression",
  "shared reality",
  "space",
  "spiritual health",
  "sponteneity",
  "stability",
  "support",
  "to back myself",
  "to be heard",
  "to create",
  "to dream",
  "to know and be known",
  "to matter",
  "touch",
  "trust",
  "understanding",
  "vitality",
  "warmth",
  "win:win",
  "(something else)",
  "(I choose to pass)",
];

export const teReoNeeds = {
  agency: {
    ma: `umanga`,
    story: `u: to be firm, fixed, resolute\numa: chest, breast.\nmanga: creek, watercourse\nnga: to take breath`,
  },
  appreciation: {
    ma: `maioha`,
    story: `mai: used to indicate action towards the person speaking it\noha: to be abundant, plentiful`,
  },
  attention: {
    ma: `whakamau`,
    story: `whaka: to cause something to happen, cause it to be\nmau: to take up, carry`,
  },
  authenticity: {
    ma: `whakatūturu`,
    story: `whaka: to cause something to happen, to become\n tūturu : to be authentic, permanent, fixed, real\n tū: to stand or take place, establish\n uru: to enter, join or be included\n ū: to be firm, fixed, resolute\nTo stand within a space that is genuine, authentic and real`,
  },
  beauty: {
    ma: `ātaahua`,
    story: `āta: careful, purposeful, intentional\nata: morning, image or reflection\nāhua: form, appearance, character\nahu: to tend, fashion`,
  },
  belonging: {
    ma: `whakauru`,
    story: `whaka: to cause something to happen, to become\nuru: to enter or be included\n ū: to be firm, fixed, resolute`,
  },
  care: {
    ma: `manaaki`,
    story: `mana: to be effectual, valid\naki: to encourage`,
  },
  celebration: {
    ma: `whakahari`,
    story: `whaka: to cause something to happen, to become\nhari - to be happy, delighted, joyful\nTo engage in being joyful and delighted`,
  },
  challenge: {
    ma: `wero`,
    story: `wē: to cryro: into wero: to bite or pierce\nthe process of challenging through a cry to establish if visitors come in peace or not`,
  },
  choice: { ma: `kōwhiri`, story: `whiri: to twist, weave, spin  ` },
  clarity: {
    ma: `mōhiotanga`,
    story: `mōhio: to understand, know, comprehend\ntanga: circumstance`,
  },
  collaboration: {
    ma: `mahi tahi`,
    story: `mahi: to work, perform, job or employment\ntahi: together, simultaneously, in uniso`,
  },
  communication: {
    ma: `whakakakautia`,
    story: `whaka: the action ofkaka: filament; part of the stem that supports another kakau: stalk of a plant or the stemtia: to steer\nthe nutrients travel from Papatūanuku, Mother Earth , through the stem to nourish the plant.\nThis is the process of steering through what is needed to move from one point to another`,
  },
  community: {
    ma: `hāpori`,
    story: `Hā: breath and essence of lifeori: group of people\n a group of people who share the essence of life`,
  },
  compassion: {
    ma: `ngākau aroha`,
    story: `ngā: to breathe kau: alone, by oneself ngākau: seat of affection aro: to be inclined towards, interested in hā: breath and essence of life\nto host a seat of affection within ones self to others`,
  },
  competence: {
    ma: `matatau`,
    story: `mata: the surface, point, tip\ntau: to come to rest`,
  },
  confidence: {
    ma: `whakamanawa`,
    story: `whaka: to cause something to happen mana: influence, control wā: time\nThe process of establishing control at the current time`,
  },
  connection: {
    ma: `whānaungatanga`,
    story: `whānau: family\nwhānaunga: family, relation\nwhānaungatanga: relationship, sense of family connection`,
  },
  consideration: {
    ma: `mākoha`,
    story: `mā: pure, to be cleankoha: gift, offeringmako: to peel or strip offoha: to greet\n a connection allowing an offering and allowances made`,
  },
  consistency: {
    ma: `pou whirinaki`,
    story: `pou: support or supporter, uprightwhiri: to weaveiri: to rest uponnaki: to move with even motionāki: urging, encouragement`,
  },
  contribution: {
    ma: `whaipainga`,
    story: `whai: to follow, chase, pursue \npainga: well-being, benefit, gain\npai: to like, approve, consent\nngā: to take breath`,
  },
  dignity: {
    ma: `kiritau`,
    story: `kiri: person, selftau: tie, to landiri: to rest uponāu: to be current\n to be steadfast within ones self`,
  },
  discovery: {
    ma: `huranga`,
    story: `hura: uncover, exposengā: plural, more than one\n to uncover something or some things`,
  },
  ease: {
    ma: `whakamauru`,
    story: `whaka: to cause something to happen, cause it to be\nuru: to enter,go in\nmauru: to be eased, appeased`,
  },
  effectiveness: {
    ma: `whakamana`,
    story: `whaka: to cause something to happen, to become\nmana: prestige, authority`,
  },
  efficiency: {
    ma: `kakama`,
    story: `kaka: used to indicate action in the future\nmā: to clean`,
  },
  empathy: {
    ma: `ngākau aroha`,
    story: `ngā: to breathekau: alone, by oneselfngākau: seat of affection aro: to be inclined towards, interested inhā: breath, essence of life\nto offer a seat of affections outwards from within ones self`,
  },
  "family health": {
    ma: `whānau`,
    story: `The capacity to belong, to care and to share where individuals are part of wider social systems. Whānau provides us with the strength to be who we are. This is the link to our ancestors, our ties with the past, the present and the future.`,
  },
  flow: {
    ma: `pātere`,
    story: `pā: to be connected with, pertaining to, regarding tere: to be swift, fast, to float or glideā: to drive, urge\nto be connected to moving energies`,
  },
  friendship: {
    ma: `hoahoa`,
    story: `hoa: friendaho: cord, string, line\n the connection between friends`,
  },
  growth: {
    ma: `tipuranga`,
    story: `tipu: to grow, increase\nranga: to raise up from the ground up\nrā: the sunLikened to the growth of a plant when being nurtured by the sun`,
  },
  honesty: {
    ma: `ngākaupono`,
    story: `ngākau: seat of affections, heart\npono: true, valid, honest\nngā: to take breath\nkau: alone, by oneselfTo hold to something that is valid. To take breath by yourself that could only bring forth the truth`,
  },
  hope: {
    ma: `tūmanako`,
    story: `tū: to stand, set in placenako: to have on ones mind\n to set in place something held within ones mind`,
  },
  humour: {
    ma: `whakakatakata`,
    story: `whaka: to cause something to happenkatakata: laughing\nsomething to cause one to laugh`,
  },
  inclusion: {
    ma: `whakawhāiti`,
    story: `whaka: to cause something to happenwhāiti: to be compactwhai: to to be equipped with \n to be connected as a small part of something greater`,
  },
  information: {
    ma: `pārongo`,
    story: `pā: to be connected witharo: to pay attention to, face towardsrongo: new, report\nto face towards or be connected to something new`,
  },
  inspiration: {
    ma: `ranga wairua`,
    story: `ranga: to raise upwairua: spirit\nto have ones spirit raised`,
  },
  integrity: {
    ma: `ngākau pono`,
    story: `ngā: to breathekau: alone, by oneselfngākau: seat of affection pono: truth\nto breathe within ones own truth`,
  },
  intimacy: {
    ma: `kauawhiawhi`,
    story: `kau: alone, unreservedly\nawhiawhi: to embrace, cuddle`,
  },
  learning: {
    ma: `akoranga`,
    story: `ako: to learn, teach, learn through reciprocity \nranga: to raise up from the ground upTo elevate through the reciprocity of sharing knowledge`,
  },
  love: {
    ma: `aroha`,
    story: `aro: to be inclined towards, interested inoha: to be abundant, plentiful, to greethā:  breath, the essence of life\nto direct an abundance of the essence of life`,
  },
  mastery: {
    ma: `matatau`,
    story: `mata: face, countancetatau: calculatetau: year, season, period of time\nto face and conquer something over a period of time`,
  },
  "mental health": {
    ma: `hinengaro`,
    story: `The capacity to communicate, to think and to feel, mind and body are inseparable. Thoughts, feelings and emotions are integral components of the body and soul.`,
  },
  mourning: {
    ma: `pōuri`,
    story: `pō: setting of the sun, darknessrī: a bond\na state bound within the absence of light`,
  },
  "movement/exercise": {
    ma: `kori`,
    story: `ko: used when talking about something specific\nrī: to screen, protect\nori: to sway`,
  },
  mutuality: {
    ma: `ngākau kotahitanga`,
    story: `ngākau: seat of affections, heart\nkotahi: to be one`,
  },
  nourishment: {
    ma: `whai painga`,
    story: `whai: to follow, chase, pursue \npainga: well-being, benefit, gain\npai: to like, approve, consent\nngā: to take breath`,
  },
  order: {
    ma: `nahanaha`,
    story: `hā: to breathe, taste, essence\nhana: to radiate`,
  },
  partnership: {
    ma: `rangapū`,
    story: `ranga: to raise uppū: bunch, bundle, clump\nto raise up in unity`,
  },
  peace: {
    ma: `rangimārie`,
    story: `rangi: heavenly, skymārie: friendly, harmonious rā: the sun, day, \na time of harmony`,
  },
  "peace-of-mind": {
    ma: `tau te āio`,
    story: `tau: subside, settle downāio: be calm, at peace\nto be calm and settled`,
  },
  perspective: {
    ma: `whakataurite`,
    story: `whaka: to cause something to happen, to become\ntau: season or period of time\nrite: to resemble, compare with, to be alike`,
  },
  "physical health": {
    ma: `tinana`,
    story: `the capacity for physical growth and development. Our physical ‘being’ supports our essence and shelters us from the external environment.`,
  },
  "physical safety": {
    ma: `marutau ōkiko`,
    story: `maru: to be sheltered, coveredtau: to come to rest, settle onkiko: body, substance, content\n to be sheltered and bring ones body to rest`,
  },
  play: {
    ma: `tākarokaro`,
    story: `tā: to dump, thump, strikekaro: dodgeako: to learntā: to breathe, lead\nThe process of moving about vicariously`,
  },
  presence: {
    ma: `awenga`,
    story: `awe: strength, power, influence\nngā: to take breath`,
  },
  privacy: {
    ma: `noho matatapu`,
    story: `noho: to occupy\nmata: face, countenance, edge\ntapu: to be sacred, restricted`,
  },
  progress: {
    ma: `kauneke`,
    story: `kau: to swim, as soon as, no sooner hadneke: to move, shift\nthe process of moving forwards`,
  },
  "psychological safety": {
    ma: `marutau hinengaro`,
    story: `maru: to be sheltered, coveredtau: to come to rest, settle onine: to measure, comparengaro: to be hiddenrō: inside\n to be safe, sheltered within ones self in a realm unseen by human eyes.`,
  },
  purpose: {
    ma: `tikanga`,
    story: `tika: to be correct, true, proper\nngā: to take breath`,
  },
  rest: {
    ma: `whakatā`,
    story: `whaka: to cause something to happen, to become\ntā: to breathe, restTo tā is to breathe with your hā, your essence. Likened to the term ‘hi, hā’ meaning to raise up and breathe out.`,
  },
  security: {
    ma: `whakahaumaru`,
    story: `whaka: the act of\nhaumaru: safe / risk free\nhau: vitality of a person/place/object\nmaru: to be covered / shelteredTo wrap around the vitality of a person forming a blanket of protection`,
  },
  "self-compassion": {
    ma: `tinana aroha`,
    story: `tina: to be fixed, fast, firmnāna: belonging toana: indeedaro: to face, pay attention tohā: the essence of breatharo: to be inclined towards, interested inhā: breath\n to set in place attention towards ones self`,
  },
  "self-expression": {
    ma: `whatumanawa`,
    story: `whatu: pupil of the eye, anchor\nmanawa: heart, seat of affections\nmana: to be binding`,
  },
  "sexual expression": {
    ma: `taera whakaahuatanga`,
    story: `taera: sexual desire\nwhaka: to cause something to happen, cause it to be\nahua: form, appearance\nhua: to flower, bloom\nahu: to tend to, nurture`,
  },
  "shared reality": {
    ma: `pānga motuhenga`,
    story: `pā: to be connected to, relate to\npānga: connections, shares\nmotu: to be set free`,
  },
  space: {
    ma: `wāhi`,
    story: `wā: time, season, duration, areahī: to raise, rise, dawn\nthe rising of a time, space or area`,
  },
  "spiritual health": {
    ma: `wairua`,
    story: `The capacity for faith and wider communication - related to unseen and unspoken energies. The spiritual essence of a person is their life force. This determines us as individuals and as a collective, who and what we are, where we have come from and where we are going.`,
  },
  sponteneity: { ma: `tene`, story: `` },
  stability: {
    ma: `pūmau`,
    story: `pū: to be exact, precise, absolute\nmau: to be held, confirmed, fixed`,
  },
  support: {
    ma: `tautoko`,
    story: `tau: to settle on, settle down, come to rest\ntoko: to prop upThe action of being held up, propped up in a space we can settle within.`,
  },
  "support others": {
    ma: `tautokotia ētahi atu`,
    story: `tau: to settle downtoko: to supportoko: to hearētahi: othersatu: to send away\nto send support outwards to others`,
  },
  "to back myself": {
    ma: `mana motuhake`,
    story: `mana: to be binding, prestige,\nmotu: to set free, escape\nake: forever\nhā: to breathe, essence`,
  },
  "to be heard": {
    ma: `hau`,
    story: `hā: sound, tone of voice\nāu: ‘you have’`,
  },
  "to create": { ma: `hanga`, story: `hā: to breathe, taste\nngā: plural` },
  "to dream": {
    ma: `whakamoemoeā`,
    story: `whaka: to cause something to happen, cause it to be\nmoe: to sleep, to dream`,
  },
  "to know and be known": {
    ma: `kei te mohio au, kei te aranga au`,
    story: `Mohio: to know/recogniseKei te mohio au : I recogniseAranga: to become knownKei te aranga au: I am known`,
  },
  "to matter": {
    ma: `whaipainga`,
    story: `whai: to possess or be equipped with pai: approve, consent, suitableāinga: driving force\n to possess approval`,
  },
  touch: { ma: `pā`, story: `` },
  trust: {
    ma: `tiakitanga`,
    story: `tiaki: to guard, keep, care, protect\ntanga: reinforcement`,
  },
  understanding: {
    ma: `mātau`,
    story: `mā: for (indicating future posession)\ntau: to come to rest, settle on\ntāu: your`,
  },
  vitality: {
    ma: `hau ora`,
    story: `hau: vitality, essence\nora: to be alive and well\nrā: the day`,
  },
  warmth: {
    ma: `mahana`,
    story: `maha: numerous, abundancehana: to shine, glow, give out radiance\n to bask in an abundant radiance`,
  },
  "wellbeing of others": {
    ma: `manaakitanga`,
    story: `manaaki: to support, take care of\ntanga: circumstance, time or place of occurence`,
  },
  "win:win": {
    ma: `eke panuku`,
    story: `eke: to get on, to rise up\npanuku: successfully - follows eke as an intensifier`,
  },
};
