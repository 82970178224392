export default [
  "agitated, turbulent",
  "angry",
  "anxious",
  "apprehensive, alarmed",
  "confused",
  "constricted chest",
  "depressed",
  "disappointed",
  "distracted",
  "embarrassed",
  "exhausted",
  "frustrated",
  "grief",
  "guarded",
  "heartbroken",
  "insecure",
  "lonely",
  "overwhelmed",
  "pain",
  "sad, disheartened",
  "scared, fear",
  "shame",
  "stressed",
  "surprised",
  "tense",
  "torn, uncertain",
  "unclear",
  "uncomfortable",
  "vulnerable",
  "wary",
  "wired",
  "worried, uneasy",
  "yearning",
  "(something else)",
  "(I choose to pass)",
];

export const teReoPeach = {
  "agitated, turbulent": {
    ma: `kakare`,
    story: `kā: to be ablaze, burning\nkakā: to be red hot, inflamed`,
  },
  angry: { ma: `puku te rae`, story: `puku: to swell\nrae: forehead, temple` },
  anxious: {
    ma: `pawera`,
    story: `pā: to be connected with, to blow as the wind\nwera: to burn or be burnt down`,
  },
  "apprehensive, alarmed": {
    ma: `mōrearea`,
    story: `mō: for, about, concerning\nrea: spring up like a shoot`,
  },
  confused: {
    ma: `kutekute`,
    story: `kū: prefix used to intensify\nkūtē: to squeeze out`,
  },
  "constricted chest": {
    ma: `poho nanati`,
    story: `poho: chest, seat of affections\nnanati: constricting, tight`,
  },
  depressed: { ma: `pōke`, story: `pō: darkness, the setting of the sun` },
  disappointed: {
    ma: `matekiri`,
    story: `mate: to be overcome, to be in want of, defeated\nkiri: a persons self (figurative sense)`,
  },
  distracted: {
    ma: `pororaru`,
    story: `poro: to cut short, trim off, abbreviate, shorten\nraru: problem, trouble, conflict`,
  },
  embarrassed: {
    ma: `whakamā`,
    story: `whaka: to cause something to happen, to become\nmā: to be faded, pale`,
  },
  exhausted: {
    ma: `mīere`,
    story: `mīere: to become powerless, be beaten, wasted, exhausted`,
  },
  frustrated: {
    ma: `matekiri`,
    story: `mate: to be overcome, to be in want of, defeated\nkiri: a persons self (figurative sense)`,
  },
  grief: {
    ma: `pūkatokato`,
    story: `pū: to lie in a heap, root, foundation, origin, source\nkato: flow or currentkatokato: to break off`,
  },
  guarded: {
    ma: `ohiti`,
    story: `ohi: to growiti: small, unimportant, insignificant`,
  },
  heartbroken: {
    ma: `pōuri`,
    story: `pō: darkness, the setting of the sun\nuri: descendant"\nTo be within a space that has descended from darkness or the absence of light"`,
  },
  insecure: {
    ma: `pānekeneke`,
    story: `pā: to be connected with, relate \ntoneke: to move, shiftne\nkeneke: movement\nTo be within a space that is unsettled, unbalanced, constantly moving`,
  },
  lonely: { ma: `mokemoke`, story: `moke: to be solitary, unsociable` },
  overwhelmed: {
    ma: `tāmomi`,
    story: `tā: to beat, thump, throw down, to be overcome\nmomi: to swallow up, siphon`,
  },
  pain: {
    ma: `mamae`,
    story: `mā: to be faded, pale\nmae: to be withered with fear`,
  },
  "sad, disheartened": {
    ma: `matapouri`,
    story: `mata: face, surface\npō: darkness, the setting of the sun\nuri: descendant`,
  },
  "scared, fear": {
    ma: `koera`,
    story: `koera: to be afraid, frightened, fearful, scared.`,
  },
  shame: {
    ma: `māteatea`,
    story: `mā: to be faded, paletea: white, clear, transparent\nteatea: clearly seen`,
  },
  stressed: {
    ma: `whakarikarika`,
    story: `whaka: to cause something to happen, to become\nrika: to be eager, impatient\nrikarika: to be restrained, stinted`,
  },
  surprised: {
    ma: `whakaohorere`,
    story: `whaka: to cause something to happen\noho: to wake up, arise, awake\nrere: to fly, flee, leap`,
  },
  tense: {
    ma: `kita`,
    story: `ki: towards, into\ntā: to beat, thump, throw down, to be overcome`,
  },
  "torn, uncertain": {
    ma: `ngākaurua`,
    story: `ngākau: mind\nrua: two"To be of two minds"`,
  },
  unclear: {
    ma: `hākirikiri`,
    story: `hā: breath, essence\nkiri: person, self - figurative`,
  },
  uncomfortable: {
    ma: `manawarau`,
    story: `manawa: heart, seat of affections\nwā: time, seasonrau: the blade of a weapon`,
  },
  vulnerable: {
    ma: `whakaraerae`,
    story: `whaka: to cause something to happen, to become\nrae: forehead, temple`,
  },
  wary: {
    ma: `tūpato`,
    story: `tū: to stand, take place\npā: to be connected with, relate\ntopatō: to snap, emit a sharp sudden sound`,
  },
  wired: {
    ma: `āmaimai`,
    story: `amai: be giddy, dizzymai: directional, meaning towards the speaker\nUsed in time expressions, seemingly for emphasis`,
  },
  "worried, uneasy": {
    ma: `āwangawanga`,
    story: `awa: river, stream\nwā: time, seasonānga: driving force, driving away, to turn to`,
  },
  yearning: {
    ma: `kaimomotu`,
    story: `momotu: to sever, separate, snap, break, depart, disappear \nkaimomotu: to yearn deeply, long for, crave`,
  },
};
