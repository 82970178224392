export default [
  "reflect if what I see outside is an expression of something inside me?",
  "let something go",
  "accept something",
  "allow something",
  "forgive something",
  "change something",
  "end something",
  "learn something",
  "face something",
  "create something",
  "make a request",
  "prepare a conversation",
  "schedule something",
  "call a friend",
  "request support",
  "move",
  "stretch",
  "exercise",
  "yoga",
  "have a cuppa",
  "lie-down",
  "listen to music",
  "meditate",
  "dance",
  "journal",
  "express a feeling",
  "(something else)",
  "(I choose to pass)",
];
