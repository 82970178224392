export default [
  "calm",
  "centered",
  "cheeky",
  "clear headed",
  "compassionate",
  "confident",
  "content",
  "curious",
  "delighted",
  "easeful",
  "empowered",
  "energetic",
  "engaged",
  "enthusiastic",
  "excited",
  "fulfilled",
  "grateful",
  "happy",
  "hopeful",
  "in flow",
  "inspired",
  "moved",
  "open hearted",
  "optimistic",
  "passionate, enlivened",
  "playful",
  "rejuvenated",
  "resonance",
  "safe",
  "secure",
  "supported",
  "trusting",
  "(something else)",
  "(I choose to pass)",
];

export const teReoGreen = {
  calm: {
    ma: `mārie`,
    story: `mā: to be clean\nari: to be clear, visible, evident`,
  },
  centered: {
    ma: `mauri tau`,
    story: `mauri: life force, vital essence\ntau: to rest, settle`,
  },
  cheeky: {
    ma: `whakatoi`,
    story: `whaka: to cause something to happen, to become\ntoi: art, knowledge, origin, source (of mankind)`,
  },
  "clear headed": {
    ma: `hinengaro mahea`,
    story: `hinengaro: intellect, consciousness\nmā: to be clear`,
  },
  compassionate: {
    ma: `mākoha`,
    story: `"to be tranquil, undisturbed, gentle, compassionate, kindly, considerate, amiable, placid, kind-hearted"`,
  },
  confident: {
    ma: `māia`,
    story: `mā: for (indicating future possession)\naiā: (interjection) that's better! I needed that!`,
  },
  content: {
    ma: `māoriori`,
    story: `mā: for (indicating future possession)\noriori: lullaby, to lull to sleep`,
  },
  curious: {
    ma: `mahira`,
    story: `mā: for (indicating future possession)\nhira: of importance, a multitude, abundance`,
  },
  delighted: {
    ma: `manahau`,
    story: `mana: charisma, freedom\nhau: vital essenceahau: I, me`,
  },
  easeful: {
    ma: `tangatanga`,
    story: `tā: to breathe, take breath, rest\nngā: to take breath, breathetānga: time or place of striking`,
  },
  empowered: {
    ma: `whakamana`,
    story: `whaka: to cause something to happen, to become\nmana: honour, prestige"To whakamana a person is to hold them up with honour and prestige"`,
  },
  energetic: { ma: `hōriri`, story: `hō: to shout, callrī: to bond` },
  engaged: {
    ma: `hiwa`,
    story: `hī: to rise, leadwā: time, season, period of time`,
  },
  enthusiastic: {
    ma: `ngākaunui`,
    story: `ngākau: seat of affections, heart, mind, soulnui: large, plentiful, numerous"To have an abundance of positive affections within your heart, mind and soul`,
  },
  excited: { ma: `hiamo`, story: `"to be thrilled, excited, exciting"` },
  fulfilled: {
    ma: `ngākau kī`,
    story: `ngākau: seat of affections\nkī: to be full\nngā: to take breath, breathe`,
  },
  grateful: {
    ma: `whakawhetai`,
    story: `" to give thanks, be grateful "`,
  },
  happy: {
    ma: `harikoa`,
    story: `"be joyful, euphoric, pleased, glad, cheerful, merry, delighted, exuberant, elated, thrilled, ecstatic, jubilant."`,
  },
  "in flow": {
    ma: `pūheke`,
    story: `pū: to blow gently\nheke: to descend`,
  },
  inspired: {
    ma: `whakaohooho`,
    story: `whaka: to cause something to happen, to become\noho: to wake up, be awakened `,
  },
  moved: { ma: `oho`, story: `"to be roused, awakened"` },
  "open hearted": {
    ma: `ngākau aroha`,
    story: `ngākau: heart\naroha: to love, be affectionate, caring`,
  },
  optimistic: {
    ma: `ngākau rorotu`,
    story: `ngākau: seat of affections\nrorotu: to be favourable\ntū: to stand`,
  },
  "passionate, enlivened": {
    ma: `wana`,
    story: `"to bud, sprout, come to life, excitement, thrill, exhilaration, fervour, verve, gusto, zeal, zest, passion, energy, sparkle, liveliness, pizazz"`,
  },
  playful: {
    ma: `tākarokaro`,
    story: `tākaro: to play with\naro: to take heed, pay attention to, interest, focus`,
  },
  rejuvenated: {
    ma: `haumanu`,
    story: `hau: vital essence\nmānu: to be floating`,
  },
  resonance: {
    ma: `tōiri`,
    story: `tō: to tingle, vibrate\niri: to be suspended, to rest upo`,
  },
  safe: {
    ma: `marutau`,
    story: `Maru: to be sheltered, shaded tau: to be settled`,
  },
  secure: {
    ma: `whakahaumaru`,
    story: `whaka: to cause something to happen\nhau: vital essence\nmānu: to be floating`,
  },
  supported: {
    ma: `taupuhipuhi`,
    story: `tau: to rest, settle\npuhipuhi: to bunch up`,
  },
  trusting: {
    ma: `whakawhirinaki`,
    story: `whaka: to cause something to happen\nwhirinaki: to lean, depend on, rely on."to lean against something, trust in, depend on, rely on"`,
  },
  hopeful: { ma: ``, story: `` },
};
