import React from "react";

import get from "lodash.get";

import {
  GreenFeeling,
  Need,
  PeachFeeling,
  RemoveIcon,
  Strategy,
  ma,
  CardOuter,
  CardEnglish,
  CardMāori,
  CardBreakdown,
  CardDisplay,
  StoryPart,
  B,
} from "./styledComponents";
import * as FirestoreService from "../services/firestore";

function rotateStyle() {
  const angleDeg = (Math.random() - 0.5) * 4;
  return { transform: `rotate(${angleDeg}deg)` };
}

function translate(word, type, lang) {
  return lang === "ma" ? ma[type][word].ma : word;
}

// TODO: hardcoded
const lang = "en";

const typeHash = {
  green: GreenFeeling,
  peach: PeachFeeling,
  need: Need,
  strategy: Strategy,
};

export default function CheckInSmall({
  checkIn,
  roomId,
  setCheckIn,
  showRemoveIcon,
  userId,
  size,
}) {
  const sortOrder = ["green", "peach", "need", "strategy"];

  const removeCheckInWord = (word) => {
    const nextCheckin = checkIn.filter((item) => item.word !== word);
    setCheckIn(nextCheckin);
    FirestoreService.updateCheckIn(nextCheckin, roomId, userId);
  };

  return (
    <CardDisplay>
      {checkIn
        .slice()
        .sort((a, b) => sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type))
        .map((item, i) => {
          const C = typeHash[item.type];
          if (size === "small") {
            return (
              <C key={i} style={rotateStyle()}>
                {translate(item.word, item.type, lang)}{" "}
                {showRemoveIcon && (
                  <RemoveIcon onClick={() => removeCheckInWord(item.word)} />
                )}
              </C>
            );
          } else {
            return (
              <CardOuter key={i} style={rotateStyle()} type={item.type}>
                <CardEnglish>{item.word}</CardEnglish>
                <CardMāori>
                  {get(ma, [item.type, item.word, "ma"], "")}
                </CardMāori>
                <CardBreakdown
                // dangerouslySetInnerHTML={{
                //   __html:
                //     <StoryPart> +
                //     get(ma, [item.type, item.word, "story"], "").split(
                //       "\n",+
                //     "</StoryPart>",
                // }}
                >
                  {get(ma, [item.type, item.word, "story"], "")
                    .split("\n")
                    .map((phrase) => (
                      <StoryPart>
                        {phrase.split(":").map((section, i) =>
                          i === 0 ? (
                            <B>{section}</B>
                          ) : (
                            <>
                              {section ? ":  " : ""}
                              {section}
                            </>
                          )
                        )}
                      </StoryPart>
                    ))}
                </CardBreakdown>
              </CardOuter>
            );
          }
        })}
    </CardDisplay>
  );
}
